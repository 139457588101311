export default {
  data() {
    return {
      newBuyItemData: [],
      newBuyItemData: [{
        "business_name": "阜康大药房（总店)",
        "drug_list": [{
          "drug_common_name": "布洛芬颗粒",
          "drug_id": 7148,
          "drug_product_name_cn": "布洛芬颗粒",
          "id": 432,
          "product_id": 25,
          "quantity": 2.00,
          "sku_list": [{
            "img_id": 111,
            "original_price": 0.01,
            "price": 0.01,
            "product_id": 25,
            "property_name": "规格名称0",
            "sku_id": 41,
            "store": 86,
            "url": require("../assets/06.jpg"),
            "value": "15g*24"
          }],
          "title": "布洛芬缓释胶囊",
          "usage_dosage": "211"
        }, {
          "drug_common_name": "布洛芬颗粒",
          "drug_id": 7148,
          "drug_product_name_cn": "布洛芬颗粒",
          "id": 436,
          "product_id": 25,
          "quantity": 2.00,
          "sku_list": [{
            "img_id": 111,
            "original_price": 0.01,
            "price": 0.01,
            "product_id": 25,
            "property_name": "规格名称0",
            "sku_id": 41,
            "store": 86,
            "url": require("../assets/06.jpg"),
            "value": "15g*24"
          }],
          "title": "布洛芬缓释胶囊",
          "usage_dosage": "一天三次"
        }, {
          "drug_common_name": "布洛芬颗粒",
          "drug_id": 7148,
          "drug_product_name_cn": "布洛芬颗粒",
          "id": 440,
          "product_id": 25,
          "quantity": 1.00,
          "sku_list": [{
            "img_id": 111,
            "original_price": 0.01,
            "price": 0.01,
            "product_id": 25,
            "property_name": "规格名称0",
            "sku_id": 41,
            "store": 86,
            "url": require("../assets/01.jpg"),
            "value": "15g*24"
          }],
          "title": "布洛芬缓释胶囊",
          "usage_dosage": "5"
        }],
        "business_id": 4
      }, {
        "business_name": "阜康大药房",
        "drug_list": [{
          "drug_common_name": "蒙脱石散",
          "drug_id": 7195,
          "drug_product_name_cn": "蒙脱石散",
          "id": 431,
          "product_id": 16,
          "quantity": 2.00,
          "sku_list": [{
            "img_id": null,
            "original_price": 0.05,
            "price": 0.02,
            "product_id": 16,
            "property_name": "含量",
            "sku_id": 37,
            "store": 100009,
            "url": require("../assets/04.jpg"),
            "value": "15ml"
          }],
          "title": "修正菊花决明子茶枸杞子金银花花茶养生茶熬夜茶包正品旗舰店官方",
          "usage_dosage": "231"
        }],
        "business_id": 1
      }, {
        "business_name": "小杨测试药店",
        "drug_list": [{
          "drug_common_name": "复方氨酚那敏颗粒",
          "drug_id": 7144,
          "drug_product_name_cn": "复方氨酚那敏颗粒",
          "id": 427,
          "img_url": require("../assets/03.jpg"),
          "product_id": 31,
          "quantity": 1.00,
          "sku_list": [{
            "img_id": 119,
            "original_price": 100.00,
            "price": 0.01,
            "product_id": 31,
            "property_name": "规格",
            "sku_id": 50,
            "store": 112,
            "url": require("../assets/03.jpg"),
            "value": "规格2"
          }, {
            "img_id": 118,
            "original_price": 100.00,
            "price": 0.01,
            "product_id": 31,
            "property_name": "规格",
            "sku_id": 49,
            "store": 86,
            "url": "/upload/2022/11/1/45678dbe-3dca-448a-a5ba-e46fde66d7f0.jpg",
            "value": "规格1"
          }],
          "title": "处方药、测试使用",
          "usage_dosage": "1"
        }, {
          "drug_common_name": "复方氨酚那敏颗粒",
          "drug_id": 7144,
          "drug_product_name_cn": "复方氨酚那敏颗粒",
          "id": 428,
          "product_id": 31,
          "quantity": 1.00,
          "sku_list": [{
            "img_id": 119,
            "original_price": 100.00,
            "price": 0.01,
            "product_id": 31,
            "property_name": "规格",
            "sku_id": 50,
            "store": 112,
            "url": require("../assets/03.jpg"),
            "value": "规格2"
          }, {
            "img_id": 118,
            "original_price": 100.00,
            "price": 0.01,
            "product_id": 31,
            "property_name": "规格",
            "sku_id": 49,
            "store": 86,
            "value": "规格1"
          }],
          "title": "处方药、测试使用",
          "usage_dosage": "123"
        }, {
          "drug_common_name": "黄体酮软胶囊",
          "drug_id": 7151,
          "drug_product_name_cn": "黄体酮软胶囊",
          "id": 429,
          "product_id": 30,
          "quantity": 10.00,
          "sku_list": [{
            "img_id": 119,
            "original_price": 1.00,
            "price": 0.02,
            "product_id": 30,
            "property_name": "1",
            "sku_id": 52,
            "store": 112,
            "url": require("../assets/04.jpg"),
            "value": "规格2"
          }, {
            "img_id": 118,
            "original_price": 0.01,
            "price": 0.01,
            "product_id": 30,
            "property_name": "1",
            "sku_id": 51,
            "store": 86,
            "url": "/upload/2022/11/1/45678dbe-3dca-448a-a5ba-e46fde66d7f0.jpg",
            "value": "规格1"
          }],
          "title": "美林退烧感冒药",
          "usage_dosage": "一天三次"
        }, {
          "drug_common_name": "复方氨酚那敏颗粒",
          "drug_id": 7144,
          "drug_product_name_cn": "复方氨酚那敏颗粒",
          "id": 433,
          "product_id": 31,
          "quantity": 4.00,
          "sku_list": [{
            "img_id": 119,
            "original_price": 100.00,
            "price": 0.01,
            "product_id": 31,
            "property_name": "规格",
            "sku_id": 50,
            "store": 112,
            "url": require("../assets/03.jpg"),
            "value": "规格2"
          }, {
            "img_id": 118,
            "original_price": 100.00,
            "price": 0.01,
            "product_id": 31,
            "property_name": "规格",
            "sku_id": 49,
            "store": 86,
            "url": "/upload/2022/11/1/45678dbe-3dca-448a-a5ba-e46fde66d7f0.jpg",
            "value": "规格1"
          }],
          "title": "处方药、测试使用",
          "usage_dosage": "一天一次"
        }, {
          "drug_common_name": "复方氨酚那敏颗粒",
          "drug_id": 7144,
          "drug_product_name_cn": "复方氨酚那敏颗粒",
          "id": 434,
          "product_id": 31,
          "quantity": 3.00,
          "sku_list": [{
            "img_id": 119,
            "original_price": 100.00,
            "price": 0.01,
            "product_id": 31,
            "property_name": "规格",
            "sku_id": 50,
            "store": 112,
            "url": require("../assets/03.jpg"),
            "value": "规格2"
          }, {
            "img_id": 118,
            "original_price": 100.00,
            "price": 0.01,
            "product_id": 31,
            "property_name": "规格",
            "sku_id": 49,
            "store": 86,
            "url": "/upload/2022/11/1/45678dbe-3dca-448a-a5ba-e46fde66d7f0.jpg",
            "value": "规格1"
          }],
          "title": "处方药、测试使用",
          "usage_dosage": "一天三次"
        }, {
          "drug_common_name": "黄体酮软胶囊",
          "drug_id": 7151,
          "drug_product_name_cn": "黄体酮软胶囊",
          "id": 438,
          "img_url": "/upload/2022/11/1/e49cfc3c-0e22-4642-bae9-efefc3091f3c.jpg",
          "product_id": 30,
          "quantity": 3.00,
          "sku_list": [{
            "img_id": 119,
            "original_price": 1.00,
            "price": 0.02,
            "product_id": 30,
            "property_name": "1",
            "sku_id": 52,
            "store": 112,
            "url": require("../assets/04.jpg"),
            "value": "规格2"
          }, {
            "img_id": 118,
            "original_price": 0.01,
            "price": 0.01,
            "product_id": 30,
            "property_name": "1",
            "sku_id": 51,
            "store": 86,
            "url": "/upload/2022/11/1/45678dbe-3dca-448a-a5ba-e46fde66d7f0.jpg",
            "value": "规格1"
          }],
          "title": "美林退烧感冒药",
          "usage_dosage": "一天两次，一次三粒"
        }],
        "business_id": 13
      }, {
        "business_name": "测试大药房",
        "drug_list": [{
          "drug_common_name": "布洛芬颗粒",
          "drug_id": 7167,
          "drug_product_name_cn": "布洛芬颗粒",
          "id": 430,
          "img_url": "/upload/2023/5/18/be19cd39-ec9f-4b5b-9e6d-3bc2deee9f29.webp",
          "product_id": 36,
          "quantity": 3.00,
          "sku_list": [{
            "img_id": 131,
            "original_price": 0.02,
            "price": 0.02,
            "product_id": 36,
            "property_name": "规格名称0",
            "sku_id": 66,
            "store": 777,
            "url": require("../assets/06.jpg"),
            "value": "003"
          }, {
            "img_id": 131,
            "original_price": 0.01,
            "price": 0.01,
            "product_id": 36,
            "property_name": "规格名称0",
            "sku_id": 65,
            "store": 999,
            "url": "/upload/2023/5/18/be19cd39-ec9f-4b5b-9e6d-3bc2deee9f29.webp",
            "value": "002"
          }, {
            "img_id": 131,
            "original_price": 0.01,
            "price": 0.01,
            "product_id": 36,
            "property_name": "规格名称0",
            "sku_id": 57,
            "store": 108,
            "url": "/upload/2023/5/18/be19cd39-ec9f-4b5b-9e6d-3bc2deee9f29.webp",
            "value": "001"
          }],
          "title": "扑热息痛",
          "usage_dosage": "123"
        }, {
          "drug_common_name": "辛伐他汀片",
          "drug_id": 7146,
          "drug_product_name_cn": "辛伐他汀片",
          "id": 435,
          "product_id": 37,
          "quantity": 1.00,
          "sku_list": [{
            "img_id": 133,
            "original_price": 0.01,
            "price": 0.01,
            "product_id": 37,
            "property_name": "规格名称0",
            "sku_id": 58,
            "store": 88,
            "url": require("../assets/01.jpg"),
            "value": "0.001"
          }],
          "title": "清热解毒片",
          "usage_dosage": "一天两次"
        }, {
          "drug_common_name": "辛伐他汀片",
          "drug_id": 7146,
          "drug_product_name_cn": "辛伐他汀片",
          "id": 437,
          "product_id": 37,
          "quantity": 2.00,
          "sku_list": [{
            "img_id": 133,
            "original_price": 0.01,
            "price": 0.01,
            "product_id": 37,
            "property_name": "规格名称0",
            "sku_id": 58,
            "store": 88,
            "url": require("../assets/02.jpg"),
            "value": "0.001"
          }],
          "title": "清热解毒片",
          "usage_dosage": "一天两次，一次两粒"
        }, {
          "drug_common_name": "辛伐他汀片",
          "drug_id": 7146,
          "drug_product_name_cn": "辛伐他汀片",
          "id": 439,
          "product_id": 37,
          "quantity": 1.00,
          "sku_list": [{
            "img_id": 133,
            "original_price": 0.01,
            "price": 0.01,
            "product_id": 37,
            "property_name": "规格名称0",
            "sku_id": 58,
            "store": 88,
            "url": require("../assets/01.jpg"),
            "value": "0.001"
          }],
          "title": "清热解毒片",
          "usage_dosage": "4"
        }, {
          "drug_common_name": "辛伐他汀片",
          "drug_id": 7146,
          "drug_product_name_cn": "辛伐他汀片",
          "id": 441,
          "product_id": 37,
          "quantity": 1.00,
          "sku_list": [{
            "img_id": 133,
            "original_price": 0.01,
            "price": 0.01,
            "product_id": 37,
            "property_name": "规格名称0",
            "sku_id": 58,
            "store": 88,
            "url": require("../assets/01.jpg"),
            "value": "0.001"
          }],
          "title": "清热解毒片",
          "usage_dosage": "2"
        }],
        "business_id": 16
      }]
    };
  }
};