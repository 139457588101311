var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "order_page"
  }, [_c('img', {
    staticClass: "bg_img",
    attrs: {
      "src": require("../assets/bg.png")
    }
  }), _c('div', {
    staticClass: "order_content"
  }, [_c('div', {
    staticClass: "text_top"
  }, [_vm._v("上海甲参科技有限公司提供")]), _vm._l(_vm.newBuyItemData, function (item, index) {
    return _c('div', {
      key: item.business_id,
      staticClass: "items_box"
    }, [_c('div', {
      staticClass: "pharmacy_box"
    }, [_c('div', {
      staticClass: "shop_name"
    }, [_c('div', [_vm._v(_vm._s(item.business_name))]), _c('span', [_vm._v("(" + _vm._s(item.drug_list.length) + ")")])]), _vm._l(item.drug_list, function (ele, idx) {
      return _c('div', {
        key: ele.business_id,
        staticClass: "good_item"
      }, [_c('div', {
        staticClass: "main_img"
      }, [_c('img', {
        attrs: {
          "src": `${ele.sku_list[0].url}`,
          "alt": ""
        }
      })]), _c('div', {
        staticClass: "right_box"
      }, [_c('div', {
        staticClass: "title"
      }, [_vm._v(_vm._s(ele.title))]), _c('div', {
        staticClass: "item_tip",
        on: {
          "click": function ($event) {
            return _vm.openSpecList(index, idx);
          }
        }
      }, [_c('span', [_vm._v(_vm._s(ele.sku_list[0].value))]), _c('van-icon', {
        attrs: {
          "name": "arrow-down"
        }
      })], 1), _c('div', {
        staticClass: "price_box"
      }, [_c('div', {
        staticClass: "price"
      }, [_c('div', [_vm._v("￥" + _vm._s(ele.sku_list[0].price))])])])])]);
    })], 2)]);
  }), _c('div', {
    staticClass: "after_btn"
  }, [_vm._v("立即支付")]), _c('div', {
    staticClass: "after_btn"
  }, [_vm._v("售后服务")])], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };